define("discourse/plugins/discourse-cakeday/discourse/components/user-info-list", ["exports", "discourse/components/user-info", "I18n", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _userInfo, _I18n, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function cakedayDate(val1, _ref) {
    let {
      isBirthday: isBirthday1
    } = _ref;
    const date1 = moment(val1);
    if (isBirthday1) {
      return date1.format(_I18n.default.t("dates.full_no_year_no_time"));
    } else {
      return date1.format(_I18n.default.t("dates.full_with_year_no_time"));
    }
  }
  const UserInfoList = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ul class="user-info-list">
      {{#each @users as |user|}}
        <li class="user-info-item">
          <UserInfo @user={{user}}>
            <div>{{cakedayDate user.cakedate isBirthday=@isBirthday}}</div>
          </UserInfo>
        </li>
      {{else}}
        <div class="user-info-empty-message"><h4>{{yield}}</h4></div>
      {{/each}}
    </ul>
  
  */
  {
    "id": "QKSbhLNV",
    "block": "[[[1,\"\\n  \"],[10,\"ul\"],[14,0,\"user-info-list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"user-info-item\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@user\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,[28,[32,1],[[30,2,[\"cakedate\"]]],[[\"isBirthday\"],[[30,3]]]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],[[[1,\"      \"],[10,0],[14,0,\"user-info-empty-message\"],[12],[10,\"h4\"],[12],[18,4,null],[13],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@users\",\"user\",\"@isBirthday\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-cakeday/discourse/components/user-info-list.js",
    "scope": () => [_userInfo.default, cakedayDate],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = UserInfoList;
});